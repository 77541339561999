import { useState, useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { liteClient } from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Configure } from 'react-instantsearch';
import { useRouter } from 'next/router';
import { useTranslation } from '../../lib/hooks';
import { Search, Close, Spinner } from '../Icons';
import SearchHits from './SearchHits';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import cx from 'classnames';
import styles from './siteSearch.module.scss';
import buildSearchResultLink from './helpers/utils';

// If you want to enable quick search, set this to true
// This will allow users to search without pressing enter
const ENABLE_QUICK_SEARCH = process.env.NEXT_PUBLIC_ALGOLIA_QUICK_SEARCH === 'true';

// Initialize Algolia client only if required env variables are set
const algoliaAppId = process.env.NEXT_PUBLIC_ALGOLIA_SITE_SEARCH_APP;
const algoliaApiKey = process.env.NEXT_PUBLIC_ALGOLIA_SITE_SEARCH_KEY;
const algoliaClient =
  algoliaAppId && algoliaApiKey ? liteClient(algoliaAppId, algoliaApiKey) : null;

const searchClient =
  algoliaClient && ENABLE_QUICK_SEARCH
    ? {
        ...algoliaClient,
        search(requests) {
          // If the query is empty or less than 2 characters, return an empty response
          if (requests.every(({ params }) => !params.query || params.query.length < 2)) {
            return Promise.resolve({
              results: requests.map(() => ({
                hits: [],
                nbHits: 0,
                nbPages: 0,
                page: 0,
                processingTimeMS: 0,
                hitsPerPage: 0,
                exhaustiveNbHits: false,
                query: '',
                params: '',
              })),
            });
          }

          return algoliaClient.search(requests);
        },
      }
    : {
        // Fallback client that returns empty results if Algolia is not configured
        search() {
          return Promise.resolve({
            results: [
              {
                hits: [],
                nbHits: 0,
                nbPages: 0,
                page: 0,
                processingTimeMS: 0,
                hitsPerPage: 0,
                exhaustiveNbHits: false,
                query: '',
                params: '',
              },
            ],
          });
        },
      };

/**
 * Component for the site search
 * @param {object} props component props
 * @param {string} props.indexName the name of the Algolia index to search
 * @param {string} props.locale the locale of the site
 * @param {Array} props.searchResultsPageMap search results page map
 * @param {string} props.popularSearchTermsTitle popular search terms title
 * @param {Array} props.popularSearchTerms popular search terms
 * @param {Array} props.popularProducts popular products
 * @param {boolean} props.searchOpen whether the search is open
 * @param {Function} props.openSearch function to open the search
 * @param {Function} props.closeSearch function to close the search
 * @returns {JSX.Element} SiteSearch component
 */
export default function SiteSearch({
  indexName,
  locale,
  searchResultsPageMap,
  popularSearchTermsTitle,
  popularSearchTerms,
  popularProducts,
  searchOpen,
  openSearch,
  closeSearch,
}) {
  const [query, setQuery] = useState('');
  const hitsRef = useRef(null);
  const router = useRouter();
  const { t } = useTranslation();
  const defaultSearchSetting = searchResultsPageMap?.find((item) => item.default === true) ?? {};
  const { contentType = '', slug = '' } = defaultSearchSetting;
  const filterPreset = contentType ? `contentType:${contentType}` : '';

  const handleInputChange = ({ uiState, setUiState }) => {
    setQuery(uiState[indexName].query);
    setUiState(uiState);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (query.trim() && slug) {
      const searchResultLink = buildSearchResultLink(locale, slug, query);
      router.push(searchResultLink);
      closeSearch();
    }
  };

  useEffect(() => {
    const handleRouteChange = () => {
      closeSearch();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, closeSearch]);

  // Lock body scroll when search is open
  useEffect(() => {
    if (hitsRef.current) {
      if (searchOpen) {
        disableBodyScroll(hitsRef.current, {
          reserveScrollBarGap: true,
          allowTouchMove: (el) => {
            while (el && el !== document.body) {
              if (el === hitsRef.current) {
                return true;
              }
              el = el.parentElement;
            }
          },
        });
      } else {
        enableBodyScroll(hitsRef.current);
      }
    }
  }, [searchOpen, hitsRef]);

  return (
    <>
      <div
        className={cx(styles.siteSearch, {
          [styles.open]: searchOpen,
        })}>
        {!searchOpen && (
          <button className={styles.searchToggle} onClick={() => openSearch()}>
            <Search />
          </button>
        )}
        {algoliaClient ? (
          <InstantSearch
            searchClient={searchClient}
            indexName={indexName || ''}
            onStateChange={handleInputChange}
            future={{ preserveSharedStateOnUnmount: true }}>
            <Configure hitsPerPage={9} filters={filterPreset} />
            <SearchBox
              classNames={{
                root: styles.searchBox,
                form: styles.searchForm,
                input: styles.searchInput,
                submit: styles.searchSubmit,
                reset: styles.searchReset,
                loadingIndicator: styles.searchLoading,
                submitIcon: styles.icon,
                resetIcon: styles.icon,
              }}
              onFocus={() => openSearch()}
              onSubmit={(event) => handleSubmit(event)}
              placeholder={t('common.ctas.search')}
              submitIconComponent={() => <Search />}
              resetIconComponent={() => <Close />}
              loadingIconComponent={() => <Spinner />}
              translations={{
                submitButtonTitle: t('common.ctas.submitSearch'),
                resetButtonTitle: t('common.ctas.clearSearch'),
              }}
            />
            <div ref={hitsRef}>
              <SearchHits
                searchOpen={searchOpen}
                popularSearchTermsTitle={popularSearchTermsTitle}
                popularSearchTerms={popularSearchTerms}
                popularProducts={popularProducts}
                locale={locale}
                slug={slug}
                query={query}
                router={router}
                closeSearch={closeSearch}
                quickSearchEnabled={ENABLE_QUICK_SEARCH}
              />
            </div>
          </InstantSearch>
        ) : (
          // Fallback for when Algolia is not configured
          <div className={styles.searchDisabled}>
            {searchOpen && <p>{t('common.search.notConfigured')}</p>}
          </div>
        )}
      </div>
      {searchOpen && (
        <AccessibleButton
          className={styles.closeSearch}
          ariaLabel={t('common.ctas.cancel')}
          onClick={() => closeSearch()}>
          {t('common.ctas.cancel')}
        </AccessibleButton>
      )}
    </>
  );
}
