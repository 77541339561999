import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import SiteContext from '../../AppContext';
import {
  InstantSearch,
  Hits,
  Configure,
  Index,
  Pagination,
  useClearRefinements,
} from 'react-instantsearch';
import cx from 'classnames';
import CustomHit from '../../CustomHit/CustomHit';
import { liteClient } from 'algoliasearch/lite';
import SearchBar from './SearchBar';
import SearchResultCategories from './SearchResultCategories';
import FacetFilters from './FacetFilters';
import NoResultsBoundary from './NoResultsBoundary';
import RefinementDisplay from './RefinementDisplay';
import styles from './searchResult.module.scss';

const algoliaClientId = process.env.NEXT_PUBLIC_ALGOLIA_SITE_SEARCH_APP;
const algoliaSearchKey = process.env.NEXT_PUBLIC_ALGOLIA_SITE_SEARCH_KEY;

// Create a fallback client when credentials are missing
const createFallbackClient = () => {
  return {
    search() {
      return Promise.resolve({
        results: [
          {
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: '',
          },
        ],
      });
    },
    // Add other methods that might be used
    initIndex() {
      return {
        search() {
          return Promise.resolve({
            hits: [],
            nbHits: 0,
          });
        },
      };
    },
  };
};

const algoliaClient =
  algoliaClientId && algoliaSearchKey
    ? liteClient(algoliaClientId, algoliaSearchKey)
    : createFallbackClient();

const searchClient = {
  ...algoliaClient,
  search(requests) {
    return new Promise((resolve) => {
      const queryLengthIsValid = requests.every(
        ({ params }) => params.query && params.query.length >= 2,
      );

      if (!queryLengthIsValid) {
        resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: '',
          })),
        });
      } else {
        resolve(algoliaClient.search(requests));
      }
    });
  },
};

/**
 * Component to handle clearing the refinement list when the content type changes.
 * @param {object} props - The props object
 * @param {string} props.contentType - The content type to filter by.
 * @returns {null} - This component doesn't render anything
 */
function ClearRefinementsOnContentTypeChange({ contentType }) {
  const { refine } = useClearRefinements();

  useEffect(() => {
    refine();
  }, [contentType, refine]);

  return null;
}

/**
 * SearchResult Page Template
 * @param {object} props - The props object
 * @param {object} props.fields - The fields object
 * @returns {JSX.Element} - The rendered component
 */
export default function SearchResult({ fields }) {
  const { relatedProducts, searchContentType } = fields;
  const siteContext = useContext(SiteContext);
  const { searchIndexName } = siteContext;
  const filterPreset = searchContentType ? `contentType:${searchContentType}` : '';
  const [openFacets, setOpenFacets] = useState({});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const facetsContainerRef = useRef(null);
  const searchResultContainerRef = useRef(null);

  const handleFacetToggle = (facetName) => {
    setOpenFacets((prev) => ({
      ...prev,
      [facetName]: !prev[facetName],
    }));
  };

  const clearOpenFacets = useCallback(() => {
    setOpenFacets({});
  }, []);

  const toggleFilters = useCallback(
    (isOpen) => {
      setFiltersOpen(isOpen);
      if (isOpen) {
        disableBodyScroll(facetsContainerRef.current, {
          allowTouchMove: (el) => {
            while (el && el !== document.body) {
              if (el === facetsContainerRef.current) {
                return true;
              }
              el = el.parentElement;
            }
          },
        });
      } else {
        enableBodyScroll(facetsContainerRef.current);
        clearOpenFacets();
      }
    },
    [clearOpenFacets],
  );

  useEffect(() => {
    // Clear open facets when the content type changes
    setOpenFacets({});
  }, [searchContentType, setOpenFacets]);

  useEffect(() => {
    const largeBreakpointSubscription = siteContext.largeBreakpointChange$.subscribe(() => {
      clearAllBodyScrollLocks();
      toggleFilters(false);
    });

    const escPressedSubscription = siteContext.escPressed$.subscribe(() => {
      clearAllBodyScrollLocks();
      toggleFilters(false);
    });

    return () => {
      largeBreakpointSubscription.unsubscribe();
      escPressedSubscription.unsubscribe();
    };
  }, [siteContext, toggleFilters]);

  useEffect(() => {
    const clickSubscription = fromEvent(document, 'click')
      .pipe(filter((e) => e.target === searchResultContainerRef.current))
      .subscribe(() => {
        clearAllBodyScrollLocks();
        toggleFilters(false);
      });

    return () => {
      clickSubscription.unsubscribe();
    };
  }, [toggleFilters]);

  return (
    <div
      className={cx(styles.container, { [styles.filtersOpen]: filtersOpen })}
      ref={searchResultContainerRef}>
      <InstantSearch
        searchClient={searchClient}
        indexName={searchIndexName}
        future={{ preserveSharedStateOnUnmount: true }}>
        <div className={styles.header}>
          <SearchBar className={styles.searchBar} clearOpenFacets={clearOpenFacets} />
          <Index indexName={searchIndexName} indexId="facet_count">
            <Configure hitsPerPage={0} facets={['contentType']} />
            <SearchResultCategories
              className={styles.categories}
              activeContentType={searchContentType}
            />
          </Index>
        </div>
        <div className={styles.searchResults}>
          <Index indexName={searchIndexName} indexId="filtered_result">
            <Configure hitsPerPage={9} facets={['*']} facetFilters={filterPreset} />
            <ClearRefinementsOnContentTypeChange contentType={searchContentType} />
            <FacetFilters
              ref={facetsContainerRef}
              className={styles.facetFilters}
              activeContentType={searchContentType}
              openFacets={openFacets}
              onFacetToggle={handleFacetToggle}
              onClose={() => toggleFilters(false)}
              isOpen={filtersOpen}
            />
            <NoResultsBoundary relatedProducts={relatedProducts} contentType={searchContentType}>
              <div className={styles.resultsWrapper}>
                <RefinementDisplay
                  className={styles.refinementDisplay}
                  onToggleFilters={toggleFilters}
                />
                <Hits
                  hitComponent={CustomHit}
                  classNames={{
                    root: styles.searchHits,
                    list: cx(styles.searchHitsList, {
                      [styles.searchHitsListProducts]: searchContentType === 'product',
                    }),
                    item: styles.searchHitsItem,
                  }}
                />
              </div>
            </NoResultsBoundary>
            <Pagination
              classNames={{
                root: styles.pagination,
                list: styles.paginationList,
                item: styles.paginationItem,
                selectedItem: styles.selectedItem,
                link: styles.paginationLink,
              }}
              padding={1}
              showFirst={false}
              showLast={false}
            />
          </Index>
        </div>
      </InstantSearch>
    </div>
  );
}
