import { useInstantSearch } from 'react-instantsearch';
import NoSearchResults from './NoSearchResults';
import RelatedProducts from '../../RelatedProducts/RelatedProducts';
import styles from './noResultsBoundary.module.scss';

/**
 * NoResultsBoundary component.
 * @param {object} props - The component props.
 * @param {object} props.children - The children to render.
 * @param {object} props.relatedProducts - The related products fields.
 * @param {string} props.contentType - The content type to filter by.
 * @returns {JSX.Element} - The component.
 */
export default function NoResultsBoundary({ children, relatedProducts = {}, contentType }) {
  const { fields } = relatedProducts;
  const { results } = useInstantSearch();
  const query = results?.query;
  if (results.nbHits === 0) {
    return (
      relatedProducts && (
        <div className={styles.container}>
          <NoSearchResults query={query} contentType={contentType} />
          {fields && (
            <RelatedProducts
              fields={fields}
              disableAnimation={true}
              className={styles.relatedProducts}
            />
          )}
        </div>
      )
    );
  }

  return children;
}
